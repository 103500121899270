<script>
import { required, email } from "vuelidate/lib/validators";
import { ParticlesBg } from "particles-bg-vue";
import { authMethods, notificationMethods } from "@/state/helpers";

export default {
  data() {
    return {
      type: "",

      email: "admin@nomadic.ae",
      password: "password",
      currentLoggedId: "",
      submitted: false,
      authenticating: false,
      showPassword: false,
    };
  },
  components: {
    ParticlesBg,
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },

  validations: {
    email: { required, email },
    password: { required },
  },

  methods: {
    AdminUser() {
      this.type = "admin";
    },

    VendorUser() {
      this.type = "vendor";
    },
    ...authMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      //stop here if form is invalid
      let userType = this.type;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        const { email, password } = this;
        if (email && password && this.type) {
          this.authenticating = true;
          this.logIn({ email, password, userType })
            .then((res) => {
              if (res.isAuth) {
                this.$router.push({ name: "programs" });
                this.currentLoggedId = res.id;
                localStorage.setItem("currentLoggedId", this.currentLoggedId);
              } else {
                console.log("NOT AUTHANTIVATED");
                this.error(res.message);
              }
            })
            .catch((err) => {
              this.error = err ? err : "";
            })
            .finally(() => {
              this.authenticating = false;
            });
        }
      }
    },
  },
};
</script>
<template>
  <div>
    <div
      style="
        background-color: #ffffffa6;
        width: 100%;
        height: 100%;
        position: absolute;
      "
    >
      <particles-bg
        type="color"
        num="1"
        :canvas="{ backgroundColor: '#fff' }"
        :bg="true"
      />
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-5">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
              <img
                src="@/assets/images/auth/Illustration.png"
                style="padding-top: 48px"
              />
            </div>
          </div>
          <div class="col-lg-7">
            <div
              class="
                authentication-page-content
                p-4
                d-flex
                align-items-center
                min-vh-100
              "
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div style="max-width: 412px; margin: auto">
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="@/assets/images/Logo_Dark.svg"
                              height="64"
                              alt="logo"
                            />
                          </a>
                        </div>
                        <h4 class="font-size-18 mt-4">Welcome Back!</h4>
                      </div>
                      <br />
                      <br />
                      <div class="mt-4 text-center user-type">
                        <label>
                          <input
                            type="radio"
                            class=""
                            name="options"
                            @click="AdminUser()"
                            autocomplete="off"
                          />
                          <span class="btn btn-outline-light m-2" for="btnAdmin"
                            >Admin</span
                          >
                        </label>
                        <label>
                          <input
                            style="margin-left: 10px"
                            type="radio"
                            class=""
                            name="options"
                            id="btnVendor2"
                            @click="VendorUser()"
                            autocomplete="off"
                          />
                          <span
                            class="btn btn-outline-light m-2"
                            for="btnVendor2"
                            >Vendor</span
                          >
                        </label>
                      </div>
                      <b-alert
                        variant="danger"
                        class="mt-3"
                        v-if="notification.message"
                        show
                        dismissible
                        >{{ notification.message }}</b-alert
                      >
                      <div class="p-2 mt-5">
                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToLogIn"
                        >
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">Email</label>
                            <input
                              type="email"
                              v-model="email"
                              class="form-control"
                              id="email"
                              placeholder="Enter email"
                              :class="{
                                'is-invalid': submitted && $v.email.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.email.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.email.required"
                                >Email is required.</span
                              >
                              <span v-if="!$v.email.email"
                                >Please enter valid email.</span
                              >
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i
                              class="ri-lock-2-line auti-custom-input-icon"
                            ></i>
                            <label for="userpassword">Password</label>
                            <input
                              v-model="password"
                              :type="showPassword ? 'text' : 'password'"
                              class="form-control"
                              id="userpassword"
                              placeholder="Enter password"
                              style="padding-right: 44px"
                              :class="{
                                'is-invalid': submitted && $v.password.$error,
                              }"
                            />
                            <button
                              type="button"
                              @click="showPassword = !showPassword"
                              class="show-password-btn"
                            >
                              <i v-if="!showPassword" class="fas fa-eye"></i>
                              <i v-else class="fas fa-eye-slash"></i>
                            </button>
                            <div
                              v-if="submitted && !$v.password.required"
                              class="invalid-feedback"
                            >
                              Password is required.
                            </div>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="
                                btn btn-primary
                                w-100
                                waves-effect waves-light
                              "
                              type="submit"
                            >
                              <span v-if="authenticating"
                                >Authenticating...</span
                              >
                              <span v-else>Sign In</span>
                            </button>
                          </div>

                          <div class="mt-4 text-center">
                            <router-link
                              tag="a"
                              to="/forgot-password"
                              class="text-muted"
                            >
                              <i class="mdi mdi-lock mr-1"></i> Forgot your
                              password?
                            </router-link>
                          </div>
                        </form>
                      </div>
                      <div class="mt-5 text-center">
                        <p>
                          © 2021 nomadictribe.com <br />Developed by MVP-APPS.AE
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.user-type [type="radio"]:checked + span {
  background: linear-gradient(45deg, #f8c616, #fa7da9);
  background-color: #8064a2;
  color: #fff;
}

.user-type [type="radio"]:checked,
[type="radio"]:not(:checked) {
  display: none;
}
.show-password-btn {
  border: none;
  position: absolute;
  top: 0px;
  right: 12px;
  width: 28px;
  height: 60px;
  background-color: transparent;
}
.show-password-btn i {
  font-size: 22px;
}
</style>